<template>
  <div class="flex justify-content-center my-8">
    <div class="shadow-3 border-round p-4 w-22rem bg-white">
      <BaseProgressSpinner v-if="isLoading" />
      <div v-else class="mb-5">
        <BaseHeadline size="6" class="text-primary">
          {{ $t("stp.register.activation.title") }}
        </BaseHeadline>
        <BaseParagraph>{{ $t("stp.register.activation.content") }}</BaseParagraph>
        <div class="text-center">
          <BaseButton
            @click="clearDelayedViewChange"
            :label="$t('stp.register.activation.login')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useApi from "@use/useApi";
import useNotifications from "@use/useNotifications";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "Register",

  setup() {
    // Setup notification
    const { showSuccess, showError } = useNotifications();
    const showSuccessMessage = ref(false);

    const router = useRouter();
    const route = useRoute();

    let waitAMoment;

    // Setup API method
    const { callApi, isLoading, backendValidation } = useApi({
      module: "usm",
      method: "activation",
      params: {
        token: route.query.token,
      },
      onMount: true,
      onSuccess: async () => {
        showSuccessMessage.value = true;
        showSuccess(
          "stp.register.notify.activation.summary",
          "stp.register.notify.activation.detail"
        );

        const delayedViewChange = () => {
          waitAMoment = window.setTimeout(() => router.push({ name: "Login" }), 5000);
        };

        await delayedViewChange();
      },
      onError: () => showError(backendValidation.value.message, backendValidation.value.message),
    });

    const clearDelayedViewChange = () => {
      waitAMoment && window.clearTimeout(waitAMoment);
      router.push({ name: "Login" });
    };

    return {
      isLoading,
      callApi,
      backendValidation,
      clearDelayedViewChange,
    };
  },
};
</script>
